<template>
  <wrapper>
    <app-header/>
    <div class="project">
      <container class="project__form-container" v-if="isSuperAdmin">
        <div class="project__form-skeleton" v-if="! projectDownloaded">
          <div class="project__form-skeleton-item skeleton-element"></div>
          <div class="project__form-skeleton-item skeleton-element"></div>
        </div>
        <form class="project__form" v-if="projectDownloaded">
          <div class="project__form-header-container">
            <div class="project__form-header container-header">
              Данные проекта
            </div>
            <router-link class="project__form-navigation" :to="{ name: 'Projects' }">
              Список проектов
            </router-link>
          </div>
          <div class="project__form-content">
            <div class="project__form-element-box project__form-element-name">
              <app-input
                  class="project__form-input"  id="name" label="Название проекта" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('name') }]"
                  v-model:model-value="projectName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="project__form-error-description form-caption-error" v-show="fieldHasError('name')" v-html="errorBag.name"></span>
            </div>
            <div class="project__form-button-group">
              <app-button class="project__form-button" button-style="button_primary" is-submit @click.prevent="updateProject">
                Сохранить
              </app-button>
              <app-button class="project__form-button" button-style="button_cancel" @click="eraseProjectData">
                Сбросить
              </app-button>
            </div>
          </div>
        </form>
      </container>
      <container class="project__form-container">
        <form class="project__form">
          <div class="project__form-header-container">
            <div class="project__form-header container-header">
              Отчет
            </div>
          </div>
          <div class="project__form-content">
            <div class="project__form-date-element-box">
              <app-input
                  class="project__form-input"  id="from" label="Дата (От)" readonly enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('from') }]"
                  v-model:model-value="reportFrom"
                  @erase="clearData" @focus="clearError" @click="showDatepicker('reportFromDatepicker')"
              />
              <span class="project__form-error-description form-caption-error" v-show="fieldHasError('from')" v-html="errorBag.name"></span>
              <v-date-picker v-show="reportFromDatepickerShow"
                             class="datepicker" mode="date"
                             v-model="reportFromDatepickerValue" :select-attribute="{ dot: 'blue' }"
                             @dayclick="setReportFromDate"
              />
            </div>
            <div class="project__form-date-element-box">
              <app-input
                  class="project__form-input"  id="to" label="Дата (До)" readonly enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('to') }]"
                  v-model:model-value="reportTo"
                  @erase="clearData" @focus="clearError" @click="showDatepicker('reportToDatepicker')"
              />
              <span class="project__form-error-description form-caption-error" v-show="fieldHasError('to')" v-html="errorBag.name"></span>
              <v-date-picker v-show="reportToDatepickerShow"
                             class="datepicker" mode="date"
                             v-model="reportToDatepickerValue" :select-attribute="{ dot: 'blue' }"
                             @dayclick="setReportToDate"
              />
            </div>
            <div class="project__form-button-date-group">
              <app-button class="project__form-date-button" button-style="button_primary" is-submit @click.prevent="reportProject">
                Скачать
              </app-button>
            </div>
          </div>
        </form>
      </container>
      <container class="project__search-container">
        <div class="project__search-dates">
          <div class="project__search-calendar">
            <div
                class="project__search-dropdown project__search-month"
                :class="{
                  'project__search-dropdown_up' : monthDropdownShown,
                  'project__search-dropdown_down' : ! monthDropdownShown
                }"
                @click="monthDropdownShown = ! monthDropdownShown"
            >
              <span class="project__search-calendar-caption">
                {{ months[selectedMonth] }}
              </span>
              <div class="project__search-dropdown-box" v-show="monthDropdownShown">
                <div class="project__search-calendar-caption project__search-calendar-caption_box" v-for="(month, index) in months" :key="index" @click="selectMonth(index)">
                  {{ month }}
                </div>
              </div>
            </div>
            <div
                class="project__search-dropdown project__search-year"
                :class="{
                  'project__search-dropdown_up' : yearDropdownShown,
                  'project__search-dropdown_down' : ! yearDropdownShown
                }"
                @click="yearDropdownShown = ! yearDropdownShown"
            >
              <span class="project__search-calendar-caption">
                {{ selectedYear }}
              </span>
              <div class="project__search-dropdown-box" v-show="yearDropdownShown">
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2022)">
                  2022
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2023)">
                  2023
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2024)">
                  2024
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2025)">
                  2025
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2026)">
                  2026
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2027)">
                  2027
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2028)">
                  2028
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2029)">
                  2029
                </div>
                <div class="project__search-calendar-caption project__search-calendar-caption_box" @click="selectYear(2030)">
                  2030
                </div>
              </div>
            </div>
          </div>
          <div class="project__search-clear">
            <span class="project__search-clear-button" @click="eraseStatusFilter">
              Сбросить
            </span>
          </div>
          <div class="project__search-days">
              <carousel :items-to-show="7" :items-to-scroll="3" :breakpoints="carouselDaysBreakpoints">
                <slide v-for="day in daysInMonth" :key="day">
                  <div
                      class="project__search-day-wrapper"
                      :class="{'project__search-day-wrapper_active' : selectedDay === day}"
                      @click="selectDay(day)"
                  >
                    <div class="project__search-day-name">{{ dayOfWeek(day) }}</div>
                    <div class="project__search-day-number">{{ day }}</div>
                  </div>
                </slide>
                <template #addons>
                  <navigation/>
                </template>
              </carousel>
          </div>
        </div>
        <div class="project__search-statuses-container">
          <div class="project__search-statuses-skeleton" v-if="! isUsersQuantityDownloaded">
            <div class="project__search-statuses-skeleton-item skeleton-element"></div>
            <div class="project__search-statuses-skeleton-item skeleton-element"></div>
            <div class="project__search-statuses-skeleton-item skeleton-element"></div>
            <div class="project__search-statuses-skeleton-item skeleton-element display-576"></div>
            <div class="project__search-statuses-skeleton-item skeleton-element display-576"></div>
            <div class="project__search-statuses-skeleton-item skeleton-element display-576"></div>
            <div class="project__search-statuses-skeleton-item skeleton-element display-576"></div>
            <div class="project__search-statuses-skeleton-item skeleton-element display-576"></div>
          </div>
          <div class="project__search-statuses" v-if="isUsersQuantityDownloaded">
            <carousel :items-to-show="3" :breakpoints="carouselStatusesBreakpoints">
              <slide v-for="(userQuantity, key) in usersQuantity" :key="key" @click="listUsersByStatus(key)">
                <div
                    class="project__search-status-box"
                    :class="{'project__search-status-box_active' : selectedStatus === key }"
                >
                  <div class="project__search-status">
                    {{ userQuantity.display }}
                  </div>
                  <div class="project__search-status-quantity">
                    {{ userQuantity.quantity }}
                  </div>
                </div>
              </slide>
              <template #addons>
                <navigation/>
              </template>
            </carousel>
          </div>
        </div>
      </container>
      <container class="project__users-form-container">
        <div class="project__form-skeleton" v-if="! rolesDownloaded">
          <div class="project__form-skeleton-item skeleton-element"></div>
          <div class="project__form-skeleton-item skeleton-element"></div>
        </div>
        <form class="project__users-form form-search" v-if="rolesDownloaded" v-show="form === 'search'">
          <div class="project__users-form-header-container">
            <div class="project__users-form-header container-header">
              Поиск
            </div>
            <div class="project__users-form-navigation form-search__navigation" @click.stop="form = 'create'; errorBag = {};">
              Новый пользователь
            </div>
          </div>
          <div class="project__users-form-content">
            <div class="project__users-form-element-box project__users-form-element-username">
              <app-input
                  class="project__users-form-input"  id="search" label="Фамилия Имя Отчество" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('search') }]"
                  v-model:model-value="filter.search"
                  @erase="clearFilter" @focus="clearError"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('search')" v-html="errorBag.search"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-element-email">
              <app-input
                  class="project__users-form-input"  id="email" label="E-mail" type="email" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('email') }]"
                  v-model:model-value="filter.email"
                  @erase="clearFilter" @focus="clearError"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('email')" v-html="errorBag.email"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-element-login">
              <app-input
                  class="project__users-form-input"  id="login" label="Логин" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('login') }]"
                  v-model:model-value="filter.login"
                  @erase="clearFilter" @focus="clearError"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('login')" v-html="errorBag.login"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-search-element-roles">
              <app-select
                  class="project__users-form-input" id="role_ids" label="Роль" enable-erase
                  :options="roles" v-model:model-value="filter.role_ids"
                  :select-class-list="[{ 'form-element-error' : fieldHasError('role_ids') }]"
                  @erase="() => { filter.role_ids = null }" @update="(event) => { filter.role_ids = event.value }" @focus="clearError"
              />
              <span class="form-caption-error" v-show="fieldHasError('role_ids')" v-html="errorBag.role_ids"></span>
            </div>
            <app-button class="project__users-form-button" button-style="button_primary" is-submit @click.prevent="listUsers">
              Поиск
            </app-button>
            <app-button class="project__users-form-button" button-style="button_cancel" @click="eraseFilter">
              Сбросить
            </app-button>
          </div>
        </form>
        <form class="project__users-form form-create" v-if="rolesDownloaded" v-show="form === 'create'">
          <div class="project__users-form-header-container">
            <div class="project__users-form-header container-header">
              Новый пользователь
            </div>
            <div class="project__users-form-navigation form-create__navigation" @click.stop="form = 'search'; errorBag = {};">
              Фильтры поиска
            </div>
          </div>
          <div class="project__users-form-content">
            <div class="project__users-form-element-box project__users-form-element-lastname">
              <app-input
                  class="project__users-form-input"  id="lastName" label="Фамилия" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('last_name') }]"
                  v-model:model-value="lastName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('last_name')" v-html="errorBag.last_name"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-element-firstname">
              <app-input
                  class="project__users-form-input"  id="firstName" label="Имя" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('first_name') }]"
                  v-model:model-value="firstName"
                  @erase="clearData" @focus="clearError"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('first_name')" v-html="errorBag.first_name"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-element-patronymic">
              <app-input
                  class="project__users-form-input"  id="patronymic" label="Отчество" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('patronymic') }]"
                  v-model:model-value="patronymic"
                  @erase="clearData" @focus="clearError"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('patronymic')" v-html="errorBag.patronymic"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-element-login">
              <app-input
                  class="project__users-form-input"  id="login" label="Логин" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('login') }]"
                  v-model:model-value="login"
                  @erase="clearData" @focus="clearError" @keyup="findUser"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('login')" v-html="errorBag.login"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-element-email">
              <app-input
                  class="project__users-form-input"  id="password" label="Пароль" type="password" enable-erase
                  :input-class-list="[{ 'form-element-error' : fieldHasError('password') }]"
                  v-model:model-value="password"
                  @erase="clearData" @focus="clearError"
              />
              <span class="project__users-form-error-description form-caption-error" v-show="fieldHasError('password')" v-html="errorBag.password"></span>
            </div>
            <div class="project__users-form-element-box project__users-form-create-element-roles">
              <app-select
                  class="project__users-form-input" id="roleId" label="Роль" enable-erase
                  :options="roles" v-model:model-value="roleId"
                  :select-class-list="[{ 'form-element-error' : fieldHasError('role_id') }]"
                  @erase="() => { roleId = null }" @update="(event) => { roleId = event.value }" @focus="clearError"
              />
              <span class="form-caption-error" v-show="fieldHasError('role_id')" v-html="errorBag.role_id"></span>
            </div>
            <app-button class="project__users-form-button" button-style="button_primary" is-submit @click.prevent="addUserToProject">
              Добавить
            </app-button>
            <app-button class="project__users-form-button" button-style="button_cancel" @click="eraseUserData">
              Сбросить
            </app-button>
          </div>
        </form>
      </container>
      <container class="project__table-container">
        <div class="project__table-skeleton" v-show="! usersDownloaded">
          <div class="project__table-skeleton-item-wrapper">
            <div class="project__table-skeleton-header skeleton-element"></div>
          </div>
          <div class="project__table-skeleton-item-wrapper">
            <div class="project__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="project__table-skeleton-item-wrapper">
            <div class="project__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="project__table-skeleton-item-wrapper">
            <div class="project__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="project__table-skeleton-item-wrapper">
            <div class="project__table-skeleton-item skeleton-element"></div>
          </div>
          <div class="project__table-skeleton-paginator-wrapper">
            <div class="project__table-skeleton-paginator skeleton-element"></div>
          </div>
        </div>
        <div class="project__table-wrapper" v-show="usersDownloaded">
          <app-table class="project__table">
            <app-thead :headers="headers" @sort="sortUsers"/>
            <app-tr v-for="user in users" :key="user.id">
              <app-td>
                <app-th>
                  Сотрудник
                </app-th>
                <app-tt class="project__table-cell-username">
                  <img :src="getAvatar(user.avatar)" alt="Аватар" class="project__avatar">
                  <span class="project__name" v-html="user.name"></span>
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Заявки
                </app-th>
                <div v-for="scheduleRequest in user.scheduleRequests" :key="scheduleRequest.id">
                  <app-tt>
                    {{ parseDateToClassicString(new Date(scheduleRequest.issued_day)) }}
                  </app-tt>
                </div>
              </app-td>
              <app-td>
                <app-th>
                  Роль
                </app-th>
                <app-tt>
                  {{ user.role }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Предпочитаемое время отгрузки
                </app-th>
                <app-tt>
                  {{ user.disembarkTime }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Размер будки (ВхГхШ)
                </app-th>
                <app-tt>
                  {{ user.carBoothSize }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Грузоподъмность машины (кг)
                </app-th>
                <app-tt>
                  {{ user.vehicleLoadCapacity }}
                </app-tt>
              </app-td>
              <app-td>
                <app-th>
                  Въезд в ТТК
                </app-th>
                <app-tt>
                  {{ user.allowedTtk }}
                </app-tt>
              </app-td>
              <app-tb>
                <app-l type="danger" :path="$route" @click.prevent="showModal(user.id)">
                  Удалить
                </app-l>
                <app-l type="primary" :path="{ path: `/projects/${$route.params.id}/users/${user.id}` }">
                  Подробнее
                </app-l>
              </app-tb>
            </app-tr>
          </app-table>
          <app-table-paginator url-name="EditProject" v-model:paginator="paginator"/>
        </div>
      </container>
    </div>
  </wrapper>
  <modal-form
      caption="Удаление пользователя из проекта"
      message="Вы уверены, что хотите удалить данного пользователя из проекта?"
      :confirm="detachUser" :cancel="closeModal"  :data="modalData" v-show="modalShown"
  />
</template>

<script>
import Wrapper from '@/layouts/Wrapper'
import Container from '@/layouts/Container'
import AppHeader from '@/components/AppHeader'
import AppInput from '@/components/AppInput'
import AppSelect from '@/components/AppSelect'
import AppButton from '@/components/AppButton'
import AppTable from '@/components/AppTable'
import AppTableHeader from '@/components/AppTableHeader'
import AppTableRow from '@/components/AppTableRow'
import AppTableTextHeader from '@/components/AppTableTextHeader'
import AppTableColumn from '@/components/AppTableColumn'
import AppTableButtonColumn from '@/components/AppTableButtonColumn'
import AppTableText from '@/components/AppTableText'
import AppTableLink from '@/components/AppTableLink'
import AppTablePaginator from '@/components/AppTablePaginator'
import ModalForm from '@/components/ModalForm'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { list as listRoles } from '@/api/roles'
import { get as getProject, update as updateProject } from '@/api/projects'
import { list as listUsers, create as createUser, attachProject, detachProject, count as countUsers } from '@/api/users'
import { isError } from '@/utils/request'
import { camelToSnake, parseDateToClassicString, getDays, getNameOfDay } from '@/utils/helpers'
import { deserialize } from 'deserialize-json-api'
import FileSaver from 'file-saver'

export default {
  name: 'EditProject',
  components: {
    Wrapper,
    Container,
    AppHeader,
    AppInput,
    AppSelect,
    AppButton,
    AppTable,
    AppThead: AppTableHeader,
    AppTr: AppTableRow,
    AppTh: AppTableTextHeader,
    AppTd: AppTableColumn,
    AppTb: AppTableButtonColumn,
    AppTt: AppTableText,
    AppL: AppTableLink,
    AppTablePaginator,
    ModalForm,
    Carousel,
    Slide,
    Navigation
  },
  data() {
    return {
      months: [
        'Январь',
        'Ферваль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      selectedDay: null,
      selectedMonth: (new Date()).getMonth(),
      selectedYear: (new Date()).getFullYear(),
      selectedStatus: null,
      usersQuantity: {
        work: {
          quantity: null,
          display: 'Рабочий'
        },
        dayOff: {
          quantity: null,
          display: 'Выходной'
        },
        sick: {
          quantity: null,
          display: 'Больничный'
        },
        vacation: {
          quantity: null,
          display: 'Отпуск'
        },
        repair: {
          quantity: null,
          display: 'Ремонт'
        },
        express: {
          quantity: null,
          display: 'Экспресс'
        },
        unknown: {
          quantity: null,
          display: 'Не указано'
        }
      },
      headers: [
        {
          field: 'last_name',
          display: 'Сотрудник'
        },
        {
          field: null,
          display: 'Заявки'
        },
        {
          field: null,
          display: 'Роль'
        },
        {
          field: null,
          display: 'Предпочитаемое время отгрузки'
        },
        {
          field: null,
          display: 'Размер будки (ВхГхШ)'
        },
        {
          field: null,
          display: 'Грузоподъмность машины (кг)'
        },
        {
          field: 'allowed_ttk',
          display: 'Въезд в ТТК'
        },
        {
          field: null,
          display: ''
        }
      ],
      createdAt: '',
      updatedAt: '',
      lastName: '',
      firstName: '',
      patronymic: '',
      password: '',
      login: '',
      roleId: null,
      roles: [],
      users: [],
      form: 'search',
      projectName: '',
      reportFrom: null,
      reportTo: null,
      reportFromDatepickerShow: false,
      reportToDatepickerShow: false,
      reportFromDatepickerValue: null,
      reportToDatepickerValue: null,
      sort: null,
      filter: {},
      userIdToAdd: null,
      loginIdToAdd: '',
      paginator: {
        total: undefined,
        count: undefined,
        current_page: 1,
        per_page: 100,
        total_pages: undefined
      },
      rolesDownloaded: false,
      projectDownloaded: false,
      usersDownloaded: false,
      usersQuantityDownloaded: {
        work: false,
        dayOff: false,
        sick: false,
        vacation: false,
        repair: false,
        express: false,
        unknown: false
      },
      errorBag: {},
      modalShown: false,
      modalData: {},
      monthDropdownShown: false,
      yearDropdownShown: false,
      carouselDaysBreakpoints: {
        576: {
          itemsToShow: 14
        },
        768: {
          itemsToShow: 7
        },
        992: {
          itemsToShow: 10
        },
        1200: {
          itemsToShow: 15
        }
      },
      carouselStatusesBreakpoints: {
        576: {
          itemsToShow: 7
        }
      }
    }
  },
  methods: {
    showDatepicker(datepicker) {
      this[datepicker + 'Show'] = true
    },

    setReportFromDate(day) {
      this.reportFromDatepickerShow = false
      this.reportFrom = parseDateToClassicString(day.date)
    },

    setReportToDate(day) {
      this.reportToDatepickerShow = false
      this.reportTo = parseDateToClassicString(day.date)
    },

    selectDay(day) {
      this.selectedDay = day
      this.updateUsersQuantity()
    },

    selectMonth(index) {
      this.selectedMonth = index
      this.selectedDay = null
    },

    selectYear(year) {
      this.selectedYear = year
      this.selectedDay = null
    },

    getAvatar(path) {
      return path || require('@/assets/svg/avatar.svg')
    },

    parseDateToClassicString(date) {
      return parseDateToClassicString(date)
    },

    clearData(field) {
      this[field] = ''
    },

    eraseProjectData() {
      this.projectName = ''
    },

    eraseUserData() {
      this.lastName = ''
      this.firstName = ''
      this.patronymic = ''
      this.email = ''
      this.login = ''
    },

    eraseStatusFilter() {
      this.selectedDay = null
      this.selectedStatus = null

      delete this.filter.status_id
      delete this.filter.day

      this.updateUsersQuantity()
      this.listUsers()
    },

    fieldHasError(field) {
      return Object.prototype.hasOwnProperty.call(this.errorBag, field)
    },

    clearError(event) {
      const field = camelToSnake(event.target.id)

      if (Object.prototype.hasOwnProperty.call(this.errorBag, field)) {
        delete this.errorBag[field]
      }
    },

    clearFilter(field) {
      delete this.filter[field]
    },

    eraseFilter() {
      this.filter = {}
    },

    sortUsers(field) {
      const query = {
        page: this.paginator.current_page,
        per_page: this.paginator.per_page
      }

      if (field !== null) {
        query.sort = field
      }

      this.sort = field

      this.$router.replace({ query })
      this.listUsers()
    },

    showModal(id) {
      this.modalData.id = id
      this.modalShown = true
    },

    closeModal() {
      this.modalShown = false
    },

    dayOfWeek(day) {
      return getNameOfDay(this.selectedYear, this.selectedMonth, day)
    },

    getQueryParams() {
      const search = new URLSearchParams(window.location.search)
      const query = {}

      if (search.has('page')) {
        this.paginator.current_page = parseInt(search.get('page'))
      } else {
        query.page = 1
      }

      if (search.has('per_page')) {
        this.paginator.per_page = parseInt(search.get('per_page'))
      } else {
        query.per_page = 100
      }

      if (search.has('has_requests')) {
        this.filter.has_requests = search.get('has_requests')
      }

      this.sort = search.has('sort') ? search.get('sort') : null

      for (const [key, value] of search.entries()) {
        query[key] = value
      }

      if (Object.keys(query).length !== 0) {
        this.$router.replace({ query })
      }
    },

    async downloadRoles() {
      const response = await listRoles(this.$store.state.user.token)

      if (isError(response.status)) {
        return
      }

      let validRoles = []

      if (this.$store.state.user.profile.role.slug === 'super_admin') {
        validRoles = ['project_admin', 'driver', 'collector']
      } else if (this.$store.state.user.profile.role.slug === 'project_admin') {
        validRoles = ['driver', 'collector']
      }

      deserialize(response.data).data
        .filter((role) => validRoles.indexOf(role.slug) !== -1)
        .forEach((role) => {
          this.roles.push({
            key: parseInt(role.id),
            value: parseInt(role.id),
            display: role.name
          })
        })

      this.rolesDownloaded = true
    },

    async getProject() {
      const response = await getProject(this.$store.state.user.token, this.$route.params.id)

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })

        return
      }

      const projectData = deserialize(response.data).data

      this.projectName = projectData.name
      this.createdAt = projectData.created_at
      this.updatedAt = projectData.updated_at
      this.projectDownloaded = true
    },

    async updateProject() {
      this.errorBag = {}

      const response = await updateProject(this.$store.state.user.token, this.$route.params.id, this.projectName)

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })
      }
    },

    async reportProject() {
      fetch(
          `${process.env.VUE_APP_API_URL}/reports/employees?project_id=${this.$route.params.id}&from=${this.reportFrom}&to=${this.reportTo}`,
          {
            method: 'GET',
            headers: {
              'Accept': 'application/vnd.api+json',
              'Authorization': 'Bearer ' + this.$store.state.user.token
            }
          }
      ).then((response) => {
        if (response.status === 200) {
          response.blob().then((file) => FileSaver.saveAs(file, 'Отчет сотрудники.xlsx'))
        }
      })
    },

    async listUsers() {
      this.errorBag = {}
      this.usersDownloaded = false

      const query = {
        page: this.paginator.current_page,
        per_page: this.paginator.per_page,
        filter: {
          project_id: this.$route.params.id
        }
      }

      if (this.sort !== null) {
        query.sort = this.sort
      }

      if (Object.keys(this.filter).length !== 0) {
        for (const [key, value] of Object.entries(this.filter)) {
          if (value !== '' && value !== null) {
            query.filter[key] = value
          }
        }
      }

      const response = await listUsers(this.$store.state.user.token, query, ['schedule_requests'])

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('filter.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        }

        this.usersDownloaded = true
        return
      }

      this.users = []

      deserialize(response.data).data.forEach((user) => {
        const scheduleRequests = Object.prototype.hasOwnProperty.call(user, 'schedule_requests')
            ? user.schedule_requests.filter(request => request.status === 'На рассмотрении').slice(0,3)
            : []
        const extra = Object.prototype.hasOwnProperty.call(user, 'extra') ?
            user.extra :
            {
              phone: '',
              vehicle_load_capacity: '',
              car_booth_size: '',
              disembark_time: '',
              allowed_ttk: ''
            }

        this.users.push({
          id: parseInt(user.id),
          avatar: user.avatar,
          name: `${user.last_name} <br class="display-992">${user.first_name} <br class="display-992">${user.patronymic ?? ''}`,
          login: user.login,
          role: user.role.name,
          roleSlug: user.role.slug,
          phone: extra.phone,
          vehicleLoadCapacity: extra.vehicle_load_capacity,
          carBoothSize: extra.car_booth_size,
          disembarkTime: extra.disembark_time,
          allowedTtk: extra.allowed_ttk === true ? 'Да' : extra.allowed_ttk === false ? 'Нет' : '',
          scheduleRequests
        })
      })

      this.paginator = response.data.meta.pagination
      this.usersDownloaded = true
    },

    async findUser() {
      const login = this.login

      if (login.length < 3) {
        return
      }

      const response = await listUsers(this.$store.state.user.token, {
        filter: { login }
      })

      const userData = deserialize(response.data).data

      if (
          isError(response.status)
          || login !== this.login
          || userData.length === 0
          || ['super_admin'].indexOf(userData[0].role.slug) !== -1
      ) {
        return
      }

      this.userIdToAdd = userData[0].id
      this.loginIdToAdd = userData[0].login
      this.lastName = userData[0].last_name
      this.firstName = userData[0].first_name
      this.patronymic = userData[0].patronymic
    },

    addUserToProject() {
      this.errorBag = {}

      if (this.login === this.loginIdToAdd) {
        this.attachUser()
      } else {
        this.createUser()
      }

      this.userIdToAdd = null
      this.login = ''
      this.password = ''
      this.firstName = ''
      this.lastName = ''
      this.patronymic = ''
    },

    async createUser() {
      const response = await createUser(this.$store.state.user.token, {
        role_id: this.roleId,
        project_id: this.$route.params.id,
        login: this.login,
        password: this.password,
        first_name: this.firstName,
        last_name: this.lastName,
        patronymic: this.patronymic
      })

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.listUsers()
      }
    },

    async attachUser() {
      const response = await attachProject(this.$store.state.user.token, this.userIdToAdd, this.$route.params.id)

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.listUsers()
      }
    },

    async detachUser(data) {
      const response = await detachProject(this.$store.state.user.token, data.id)

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })
      } else {
        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })

        this.listUsers()
      }

      this.modalData = {}
      this.closeModal()
    },

    async countUsers(slug) {
      const response = await countUsers(
          this.$store.state.user.token,
          this.$route.params.id,
          camelToSnake(slug),
          this.selectedDay ? `${this.selectedYear}-${this.selectedMonth + 1}-${this.selectedDay}` : undefined
      )

      if (isError(response.status)) {
        this.$notify({
          type: 'error',
          text: response.data.errors.title
        })

        return
      }

      this.usersQuantity[slug].quantity = deserialize(response.data).data.employees
      this.usersQuantityDownloaded[slug] = true
    },

    updateUsersQuantity() {
      this.usersQuantity = {
        work: {
          quantity: 0,
          display: 'Рабочий'
        },
        dayOff: {
          quantity: 0,
          display: 'Выходной'
        },
        sick: {
          quantity: 0,
          display: 'Больничный'
        },
        vacation: {
          quantity: 0,
          display: 'Отпуск'
        },
        repair: {
          quantity: 0,
          display: 'Ремонт'
        },
        express: {
          quantity: 0,
          display: 'Экспресс'
        },
        unknown: {
          quantity: 0,
          display: 'Не указано'
        }
      }

      if (this.selectedDay === null) {
        this.setStatusToUsersQuantityStatuses(true)
        return
      }

      this.setStatusToUsersQuantityStatuses(false)

      for (const key in this.usersQuantity) {
        this.countUsers(key)
      }
    },

    setStatusToUsersQuantityStatuses(status) {
      for (const key in this.usersQuantityDownloaded) {
        this.usersQuantityDownloaded[key] = status
      }
    },

    listUsersByStatus(slug) {
      if (this.selectedDay === null) {
        return
      }

      this.selectedStatus = slug
      this.filter.status = camelToSnake(slug)
      this.filter.day = `${this.selectedYear}-${this.selectedMonth + 1}-${this.selectedDay}`

      this.listUsers()
    }
  },
  computed: {
    daysInMonth() {
      return getDays(this.selectedYear, this.selectedMonth + 1)
    },

    currentPage() {
      return this.$route.query.page
    },

    isUsersQuantityDownloaded() {
      for (const key in this.usersQuantityDownloaded) {
        if (! this.usersQuantityDownloaded[key]) {
          return false
        }
      }

      return true
    },

    isSuperAdmin() {
      return this.$store.state.user.profile.role.slug === 'super_admin'
    },

    hasRequests() {
      return this.$route.query.has_requests
    }
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (oldPage !== undefined && newPage !== oldPage) {
        this.paginator.current_page = newPage
        this.listUsers()
      }
    },

    hasRequests(newValue, oldValue) {
      if (newValue && ! oldValue) {
        this.getQueryParams()
        this.listUsers()
      } else {
        if (Object.prototype.hasOwnProperty.call(this.filter, 'has_requests')) {
          delete this.filter.has_requests
        }
      }
    }
  },
  async created() {
    this.getQueryParams()
    this.downloadRoles()
    this.getProject()
    this.listUsers()
    this.updateUsersQuantity()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.project__search-container {
  margin-bottom: 14px;
}

.project__form-container, .project__users-form-container {
  margin-bottom: 14px;
  padding: 25px 20px 20px;
}

.project__search-dates {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-top: 25px;
  border-bottom: 1px solid #ebeef5;
}

.project__search-calendar {
  display: flex;
  gap: 20px;
  padding-left: 20px;
  font-size: 14px;
}

.project__search-dropdown {
  position: relative;
  cursor: pointer;

  &::before, &::after {
    content: "";
    position: absolute;
    top: 7px;
    width: 8px;
    border-bottom: 1px solid #000;
  }

  &_up {
    &::before {
      right: 0;
      transform: rotate(45deg);
    }

    &::after {
      right: 5px;
      transform: rotate(-45deg);
    }
  }

  &_down {
    &::before {
      right: 0;
      transform: rotate(-45deg);
    }

    &::after {
      right: 5px;
      transform: rotate(45deg);
    }
  }
}

.project__search-calendar-caption_box:hover {
  background-color: $separator-color;
}

.project__search-dropdown-box {
  position: absolute;
  left: 0;
  top: 22px;
  min-width: 100px;
  width: 100%;
  padding: 10px 0;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  color: #444;
  background-color: #fff;
  border: 1px solid $separator-color;
  border-radius: $border-radius;
  z-index: 999;
}

.project__search-month, .project__search-year {
  padding-right: 20px;
}

.project__search-days {
  width: calc(100% - 100px);
  margin: 25px 50px -1px;
}

.project__search-statuses {
  margin: 0 50px -1px;
}

.project__search-day-name {
  font-size: 13px;
}

.project__search-day-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  &_active .project__search-day-number, &_active .project__search-day-name {
    color: $primary-links-color;
  }

  &_active .project__search-day-number {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $primary-links-color;
    }
  }
}

.project__search-day-number_active ~ .project__search-day-name {
  color: $primary-links-color;
}

.project__search-day-number {
  font-size: 16px;
}

.project__search-day-number, .project__search-status-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 21px;
}

.project__search-status-box {
  cursor: pointer;

  &_active {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $primary-links-color;
    }

    & .project__search-status, & .project__search-status-quantity {
      color: $primary-links-color;
    }
  }
}

.project__search-status {
  font-size: 11px;
  color: #555;
}

.project__search-status-quantity {
  font-family: "Inter Medium", "sans-serif";
  font-weight: 500;
  font-size: 13px;
  color: #333;
}

.project__form-header-container, .project__users-form-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.project__search-clear {
  padding-right: 20px;
  text-align: right;
}

.project__search-calendar, .project__search-clear {
  flex-grow: 1;
  padding-bottom: 25px;
  border-bottom: 1px solid #ebeef5;
}

.project__search-clear-button {
  position: relative;
  font-size: 12px;
  color: $primary-links-color;
  cursor: pointer;
}

.project__form-navigation, .form-search__navigation, .form-create__navigation {
  position: relative;
  font-size: 12px;
  color: $primary-links-color;
  cursor: pointer;

  &::before, &::after {
    content: "";
    position: absolute;
    width: 6px;
    border-bottom: 1px solid $primary-links-color;
  }
}

.project__form-navigation, .form-create__navigation {
  padding-left: 12px;
}

.project__form-navigation, .form-search__navigation {
  padding-right: 12px;
}

.project__form-navigation, .form-search__navigation {
  &::after {
    top: 5.5px;
    right: 0;
    transform: rotate(45deg);
  }

  &::before {
    top: 9px;
    right: 0;
    transform: rotate(-45deg);
  }
}

.form-create__navigation {
  &::after {
    top: 9px;
    left: 0;
    transform: rotate(45deg);
  }

  &::before {
    top: 5.5px;
    left: 0;
    transform: rotate(-45deg);
  }
}

.project__form-error-description, .project__users-form-error-description {
  display: block;
  margin-top: 10px;
}

.project__form-content, .project__users-form-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.project__form-element-box, .project__form-button-group, .project__users-form-element-box, .project__form-date-element-box, .project__form-date-button {
  flex-basis: 100%;
}

.project__form-date-element-box {
  position: relative;
}

.project__form-input, .project__users-form-input {
  width: 100%;
}

.project__form-button-group {
  display: flex;
  gap: 20px;
}

.project__form-button {
  flex-grow: 1;
}

.project__users-form-button {
  flex-basis: calc(50% - 10px);
}

.project__avatar {
  display: none;
}

.project__form-button-date-group {
  display: flex;
  flex-basis: 100%;
  gap: 20px;
}

.datepicker {
  position: absolute;
  top: 75px;
  left: 0;
  z-index: 999;
}

/* skeleton */
.project__search-statuses-skeleton {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
}

.project__search-statuses-skeleton-item {
  width: 71px;
  height: 24px;
}

.project__form-skeleton {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project__form-skeleton-item {
  width: 100%;
  height: 68px;
}

.project__table-skeleton {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
}

.project__table-skeleton-item-wrapper, .project__table-skeleton-paginator-wrapper {
  padding: 15px 20px;
}

.project__table-skeleton-item-wrapper {
  border-bottom: 1px solid $border-color;
}

.project__table-skeleton-header {
  width: 70%;
  height: 23px;
}

.project__table-skeleton-item {
  width: 100%;
  height: 200px;
}

.project__table-skeleton-paginator {
  width: 100%;
  height: 23px;
}

@media screen and (min-width: 576px) {
  .project__search-container {
    margin-bottom: 28px;
  }

  .project__search-dates {
    padding: 25px 0 0;
  }

  .project__search-statuses-container {
    padding: 0 10px;
  }

  .project__form-date-element-box {
    flex-basis: 35%;
  }

  .project__search-dropdown::before, .project__search-dropdown::after {
    top: 10px;
  }

  .project__search-calendar {
    font-size: 18px;
  }

  .project__search-day-number, .project__search-status-box {
    padding-bottom: 30px;
  }

  .project__form-container, .project__users-form-container {
    margin-bottom: 28px;
    padding: 25px 20px 30px;
  }

  .project__search-days {
    margin: 25px 50px -1px;
  }

  .project__search-statuses {
    margin: 0 0 -1px;
  }

  .project__search-clear-button {
    font-size: 14px;
  }

  .project__form-navigation, .form-search__navigation, .form-create__navigation {
    font-size: 14px;
  }

  .project__form-navigation, .form-search__navigation {
    &::before {
      top: 11px;
    }

    &::after {
      top: 7.5px;
    }
  }

  .form-create__navigation {
    &::before {
      top: 7.5px;
    }

    &::after {
      top: 11px;
    }
  }

  .project__form-content, .project__users-form-content {
    gap: 30px 20px;
  }

  .project__form-button-group, .project__form-button-date-group {
    justify-content: flex-end;
  }

  .project__form-button-date-group {
    flex-basis: calc(30% - 60px);
  }

  .project__form-button {
    flex-basis: calc(percentage(119 / 534) - 15px);
    flex-grow: 0;
  }

  .project__users-form-element-firstname, .project__users-form-element-patronymic {
    flex-basis: calc(50% - 10px);
  }

  .project__users-form-element-email, .project__users-form-element-login, .project__users-form-search-element-roles, .project__users-form-create-element-roles {
    flex-basis: calc(50% - 10px);
  }

  .project__users-form-button {
    flex-basis: calc(25% - 15px);
  }

  .project__avatar {
    display: inline;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: $border-radius;
  }

  /* skeleton */
  .project__search-statuses-skeleton {
    padding-bottom: 30px;
  }

  .project__search-statuses-skeleton-item {
    width: 10%;
    height: 24px;
  }

  .project__form-skeleton {
    gap: 30px;
  }

  .project__table-skeleton-paginator {
    width: 288px;
    height: 23px;
  }
}

@media screen and (min-width: 768px) {
  .project__search-day-number, .project__search-status-box {
    padding-bottom: 25px;
  }

  .project__search-dates {
    flex-direction: row;
    gap: 25px;
    margin-bottom: 30px;
  }

  .project__search-calendar, .project__search-clear {
    padding-bottom: 0;
  }

  .project__search-calendar {
    flex-basis: 25%;
    flex-grow: 0;
    order: 1;
    border: none;
  }

  .project__search-clear {
    flex-basis: 10%;
    order: 3;
  }

  .project__search-days {
    flex-basis: 40%;
    width: calc(40% - 100px);
    margin: 0 50px -1px;
    order: 2;
  }

  .project__search-day-name {
    font-size: 14px;
  }

  .project__search-day-number {
    font-size: 17px;
  }

  .project__form-container, .project__users-form-container {
    padding: 25px 20px 35px;
  }

  .project__search-status {
    font-size: 12px;
  }

  .project__search-status-quantity {
    font-size: 14px;
  }

  .project__search-clear-button {
    font-size: 15px;
  }

  .project__form-navigation, .form-search__navigation, .form-create__navigation {
    font-size: 15px;
  }

  .project__form-navigation, .form-search__navigation {
    &::before {
      top: 11.5px;
    }

    &::after {
      top: 8px;
    }
  }

  .form-create__navigation {
    &::before {
      top: 8px;
    }

    &::after {
      top: 11.5px;
    }
  }

  .project__form-content, .project__form-button-group, .project__users-form-content {
    gap: 30px;
  }

  .project__users-form-element-lastname, .project__users-form-element-firstname, .project__users-form-element-patronymic {
    flex-basis: calc(100% / 3 - 60px / 3);
  }

  .project__users-form-element-email, .project__users-form-element-login, .project__users-form-search-element-roles, .project__users-form-create-element-roles {
    flex-basis: calc(50% - 15px);
  }

  .project__users-form-button {
    flex-basis: calc(25% - 45px / 2);
  }
}

@media screen and (min-width: 992px) {
  .project__search-dates {
    padding: 25px 0 0;
  }

  .project__search-day-number, .project__search-status-box {
    flex-direction: row;
    align-items: flex-end;
  }

  .project__form-container, .project__users-form-container {
    padding: 25px 30px 30px;
  }

  .project__search-statuses-container {
    padding: 0 20px;
  }

  .project__search-calendar {
    flex-basis: 20%;
    padding-left: 30px;
  }

  .project__search-clear {
    flex-basis: 10%;
    padding-right: 30px;
  }

  .project__search-days {
    flex-basis: 50%;
    width: calc(50% - 100px);
  }

  .project__search-status {
    font-size: 14px;
  }

  .project__search-status-quantity {
    font-size: 15px;
  }

  .project__form-element-box {
    flex-basis: calc(65% - 15px);
  }

  .project__form-button-group {
    flex-basis: calc(35% - 15px);
  }

  .project__form-button {
    flex-grow: 1;
  }

  .project__avatar {
    position: static;
  }

  .project__table-cell-username {
    display: flex;
    gap: 20px;
  }

  /* skeleton */
  .project__table-skeleton-item-wrapper, .project__table-skeleton-paginator-wrapper {
    padding: 15px 30px;
  }

  .project__table-skeleton-item {
    height: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .project__search-status-box {
    margin-bottom: 0.25px;
  }

  .project__search-day-number {
    margin-bottom: -0.25px;
  }

  .project__search-dates {
    padding: 35px 0 0;
  }

  .project__search-statuses-container {
    padding: 0 30px;
  }

  .project__search-calendar {
    flex-basis: 15%;
    padding-left: 40px;
  }

  .project__search-clear {
    flex-basis: 10%;
    padding-right: 40px;
  }

  .project__search-days {
    flex-basis: 55%;
    width: calc(55% - 100px);
  }

  .project__form-container, .project__users-form-container {
    padding: 35px 40px 40px;
  }

  .project__users-form-element-lastname, .project__users-form-element-firstname, .project__users-form-element-patronymic {
    flex-basis: calc(percentage(257.5 / 1030) - 90px / 4);
  }

  .project__users-form-element-username {
    flex-basis: calc(percentage(736 / 1090) - 15px);
    order: 1;
  }

  .project__users-form-search-element-roles {
    flex-basis: calc(percentage(354 / 1090) - 15px);
    order: 2;
  }

  .project__users-form-create-element-roles {
    flex-basis: calc(percentage(257.5 / 1030) - 90px / 4);
    order: 2;
  }

  .project__users-form-element-login {
    flex-basis: calc(percentage(353 / 1030) - 90px / 4);
    order: 3;
  }

  .project__users-form-element-email {
    flex-basis: calc(percentage(353 / 1030) - 90px / 4);
    order: 4;
  }

  .project__users-form-button {
    flex-basis: calc(percentage(162 / 1030) - 90px / 4);
    order: 5;
  }

  /* skeleton */
  .project__table-skeleton-item-wrapper, .project__table-skeleton-paginator-wrapper {
    padding: 15px 40px;
  }
}
</style>

<style lang="scss">
@import "../assets/scss/variables";

.display-576, .display-992 {
  display: none;
}

/* carousel */
.carousel__icon {
  display: none;
}

.carousel__prev {
  left: -30px;
}

.carousel__next {
  right: -30px;
}

.carousel__prev, .carousel__next {
  margin: 0;
  top: 18px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border: 1.5px solid $separator-color;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.carousel__prev::before {
  left: 0;
  background-image: url("../assets/svg/arrow-left.svg");
}

.carousel__next::before {
  right: 0;
  background-image: url("../assets/svg/arrow-right.svg");
}

@media screen and (min-width: 576px) {
  .display-576 {
    display: inline;
  }

  .project__search-statuses .carousel__prev, .project__search-statuses .carousel__next {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .display-992 {
    display: inline;
  }
}
</style>